<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-05-18 15:18:05
 * @ Description: Fixer's payment redirect page.
 -->

<template>
    <div
        style="background-color: #F9FBFD; height: 100vh; display: flex; align-items: center;"
    >
        <v-row
            align="center"
        >
            <v-col
                cols="12"
                class="text-center rob-40-m py-0"
                style="color: rgba(136, 138, 141);"
            >
                Payment Gateyway Redirect
            </v-col>
            <v-col
                cols="12"
                class="text-center"
            >
                <v-progress-circular
                    indeterminate
                    color="#FFA858"
                />
            </v-col>
            <v-col
                cols="12"
                class="text-center rob-20-m pt-0"
                style="color: rgba(136, 138, 141);"
            >
                Please wait...
            </v-col>
        </v-row>
    </div>
</template>
<script>
    export default {
        name: 'GeneralPaymentRedirectPage'
    }
</script>
<style scoped>
    @import url('../../assets/style/style.fonts.css');
</style>
